
import { DataGame_GMSearchDailyDataStatistic } from '@/apis/data_game';
import MixedChart from '@/components/charts/MixedChart.vue';
import { EPayType } from '@/constant/network';
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { TodayEnd, TodayStart } from "@/utils/timer";
import { SeriesOption } from 'echarts';
import { ElForm, ElMessage } from 'element-plus';
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    components: {
        MixedChart,
    },
    setup() {
        const { t } = useI18n()
        const dataForm = ref<typeof ElForm>()
        const dataMap = reactive({
            time: Date,
            tableKey: 0,
            list: [] as ITServerZoneDailyDataStatisticItem[],
            total: 1,
            listLoading: true,
            todayOrderIncome: 0,
            payTypeCount: {} as { [key: string]: number },
            starttime: TodayStart(),
            endtime: TodayEnd(),
            listQuery: {
                title: "",
                label: "Day",
                sort: "+id"
            },
            xAxisOptions: [] as string[],
            xChartData: {} as { [k: string]: Partial<SeriesOption & { sortid: number }> },
            labelOptions: ["Day", "Week", "Month"],
            downloadLoading: false,
        })

        const getDataList = async () => {
            dataMap.listLoading = true;
            dataMap.list = [];
            let msg = {} as IC2G_GMSearchDailyDataStatistic;
            msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
            msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
            let cbmsg = await DataGame_GMSearchDailyDataStatistic(msg);
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`GetLogFail: ${cbmsg?.Message}`);
                return;
            }
            if (cbmsg.SearchResult) {
                for (let k of cbmsg.SearchResult) {
                    let record = JSON.parse(k) as ITServerZoneDailyDataStatisticItem;
                    record.Id = record._id;
                    dataMap.list.push(record);
                }
                dataMap.list.sort((a, b) => {
                    return a.Year * 365 + a.Month * 30 + a.Day - (b.Year * 365 + b.Month * 30 + b.Day);
                })
            }
            if (cbmsg.Message) {
                let recordToday = JSON.parse(cbmsg.Message) as ITServerZoneDailyDataStatisticItem;
                recordToday.OrderIncome.forEach(v => {
                    dataMap.todayOrderIncome += v[1];
                })
            }
            handleChange(dataMap.listQuery.label);
            dataMap.total = cbmsg.SearchCount;
            dataMap.listLoading = false;
        }
        const handleChange = (val: string) => {
            dataMap.xAxisOptions = [];
            dataMap.xChartData = {};
            let vdata = {} as { [k: string]: number[] };
            if (val === "Day") {
                dataMap.list.forEach(v => {
                    v.OrderIncome.forEach((_v, index) => {
                        vdata[_v[0] + ""] = vdata[_v[0] + ""] || [];
                        vdata[_v[0] + ""].push(_v[1]);
                    })
                    dataMap.xAxisOptions.push(`${v.Year}/${v.Month} ${v.Day}号`);
                })
            }
            else if (val === "Week") {
                let weekindex = 1;
                const weekcount = {} as { [k: string]: { [k: string]: number } };
                dataMap.list.forEach(v => {
                    if (v.Day < 8) {
                        weekindex = 1;
                    }
                    else if (v.Day < 15) {
                        weekindex = 2;
                    }
                    else if (v.Day < 22) {
                        weekindex = 3;
                    }
                    else {
                        weekindex = 4;
                    }
                    const Xkey = `${v.Year}/${v.Month} ${weekindex}周`;
                    weekcount[Xkey] = weekcount[Xkey] || {};
                    v.OrderIncome.forEach((_v, index) => {
                        weekcount[Xkey][_v[0] + ""] = weekcount[Xkey][_v[0] + ""] || 0;
                        weekcount[Xkey][_v[0] + ""] += _v[1];
                    });
                    if (dataMap.xAxisOptions.includes(Xkey) === false) {
                        dataMap.xAxisOptions.push(Xkey);
                    }
                })
                dataMap.xAxisOptions.forEach(Xkey => {
                    for (let k in weekcount[Xkey]) {
                        vdata[k] = vdata[k] || [];
                        vdata[k].push(weekcount[Xkey][k]);
                    }
                })
            }
            else if (val === "Month") {
                const monthcount = {} as { [k: string]: { [k: string]: number } };
                dataMap.list.forEach(v => {
                    const Xkey = `${v.Year}-${v.Month}`;
                    monthcount[Xkey] = monthcount[Xkey] || 0;
                    v.OrderIncome.forEach((_v, index) => {
                        monthcount[Xkey][_v[0] + ""] = monthcount[Xkey][_v[0] + ""] || 0;
                        monthcount[Xkey][_v[0] + ""] += _v[1];
                    });
                    if (dataMap.xAxisOptions.includes(Xkey) === false) {
                        dataMap.xAxisOptions.push(Xkey);
                    }
                })
                dataMap.xAxisOptions.forEach(Xkey => {
                    for (let k in monthcount[Xkey]) {
                        vdata[k] = vdata[k] || [];
                        vdata[k].push(monthcount[Xkey][k]);
                    }
                })
            }
            const totaldata = [] as number[];
            let i = 0;
            for (let k in vdata) {
                i++;
                dataMap.xChartData[EPayType[k as any] || "unknow"] = {
                    sortid: i,
                    data: vdata[k].map(v => v / 100),
                }
                for (let i = 0; i < vdata[k].length; i++) {
                    if (totaldata[i] == null) {
                        totaldata[i] = 0;
                    }
                    totaldata[i] = totaldata[i] + vdata[k][i];
                }
            }
            dataMap.xChartData["Total"] = {
                sortid: 1000,
                data: totaldata.map(v => v / 100),
            }

        }
        const handleDownload = () => {
            dataMap.downloadLoading = true;
            const tHeader = ["Time", "Year", "Month", "Day", "ServerID", "HoursPlayerNew", "HoursPlayerOnline", "HoursBattleCount", "OrderIncome", "ShopSellItem"];
            const filterVal = ["Time", "Year", "Month", "Day", "ServerID", "HoursPlayerNew", "HoursPlayerOnline", "HoursBattleCount", "OrderIncome", "ShopSellItem"];
            const data = formatJson(filterVal, dataMap.list);
            exportJson2Excel(tHeader, data, "DataPlayerNew");
            dataMap.downloadLoading = false;
        }

        onMounted(() => {
            getDataList()
        })

        return {
            t, ...toRefs(dataMap),
            dataForm,
            getDataList,
            handleChange,
            handleDownload
        }
    }
})

